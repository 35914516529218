<template>
  <div class="Vehicle__Entity">
    <Entity
      ref="entity"
      entity-type="Vehicle"
      :entity-id="vehicleId"
      :innerComponent="innerComponent"
      innerComponentTitle="פרטי רכב"
      title="רכב"
      back-button-text="רשימת רכבים"
      :back-button-url="!isAdminWithoutProfileId ? 'vehicles' : 'admin/search/vehicles'"
      :widgets="widgets"
      :actions="actions"
      :preview="preview"
      :read-only="readOnly"
      :entityReady="entityReady"
      :activities="false"
      :OnProps="OnProps"
      @onFormSave="onFormSave"
      @onFormDiscard="onFormDiscard"
    >
      <template #mandatory>
        <div class="Accident__Entity__Details__BaseDetails__Stage">
          <span class="ltr">מספר רכב:</span>
          <span
            class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            "
            >{{ PlateNumber }}</span
          >
        </div>
        <div
          v-if="CreateDate"
          class="Accident__Entity__Details__BaseDetails__Stage"
        >
          <span class="ltr">תאריך יצירה:</span>
          <span
            class="
              Accident__Entity__Details__BaseDetails__Stage__Value
              value
              has-text-weight-bold
            "
            >{{ displayDate }}</span
          >
        </div>
      </template>
    </Entity>
  </div>
</template>

<script>
// import DriverService from '@/services/DriverService';
import Entity from "@/components/Entity.vue";
import VehicleEntityDetails from "@/components/VehicleEntityDetails.vue";
// import EntityMixin from '@/mixins/EntityMixin';
import moment from "moment";
import { mapActions } from "vuex";
import { createHelpers } from "vuex-map-fields";
import VehicleService from "@/services/VehicleService";
import ReportService from "@/services/ReportService";
import DialogReportHistory from "@/components/DialogReportHistory.vue";
import WidgetService from "../services/WidgetService";

const { mapFields } = createHelpers({
  getterType: "vehicle/getField",
  mutationType: "vehicle/updateField",
});

export default {
  components: {
    Entity,
  },
  props: ["id", "preview", "readOnly"],
  computed: {
    ...mapFields(["PlateNumber", "CreateDate"]),

    displayDate() {
      return moment(this.CreateDate).format("DD/MM/YYYY");
    },
    vehicleId() {
      return this.$route.params.id || this.id;
    },
    isAdmin() {
      return this.$store.state.auth?.user?.claims?.isAdmin === "True";
    },
    isAdminWithoutProfileId() {
      return !this.$store.state.auth.profileId && this.$store.state.auth?.user?.claims?.isAdmin === "True";
    },
    widgets() {
      return [
        { ...WidgetService.VehicleCurrentOwnership, namespace: "vehicle", isAdmin: this.isAdmin },
        {
          ...WidgetService.VehicleOwners,
          namespace: "vehicle",
          delegate: VehicleService.getOwnersAdmin,
        },
        { ...WidgetService.VehicleContracts, namespace: "vehicle" },
        {
          ...WidgetService.DriverContractForVehicle,
          namespace: "vehicle",
          delegate: VehicleService.getAdminDriverContracts
        },
        {
          ...WidgetService.Reports,
          namespace: "vehicle",
          query: (VehicleId) => ({ VehicleId }),
          isAdmin: true,
          queryParams: { PlateNumber: this.PlateNumber },
          delegate: ReportService.adminSearch
        },
        {
          ...WidgetService.VehicleDrives,
          namespace: "vehicle",
          query: (VehicleId) => ({ VehicleId }),
        },
        // { ...WidgetService.VehicleAccidents, namespace: 'vehicle' },
      ];
    },

    actions() {
      return [
        {
          text: "משוך נתוני רכב",
          onClick: () => {
            VehicleService.getVehicleDetails(this.PlateNumber).then((r) => {
              this.model.Model = r.data.kinuy_mishari || r.data.degem_nm;
              this.model.Year = r.data.shnat_yitzur;
              this.model.Color = r.data.tzeva_rechev.slice(0, 10);
              this.model.PlateNumber = this.PlateNumber;
              this.model.Manufacturer = r.data.tozeret_nm;
              this.OnProps = { ...this.model };
            });
          },
        },
        {
          text: "היסטורית פעילות",
          onClick: () => {
            VehicleService.getVehicleHistory(this.vehicleId).then((r) => {
              this.data = r.data;
              this.$buefy.modal.open({
                component: DialogReportHistory,
                props: {
                  data: this.data,
                },
                canCancel: false,
                hasModalCard: true,
                trapFocus: true,
              });
            });
          },
        },
      ];
    },
    innerComponent() {
      return VehicleEntityDetails;
    },
    ...mapFields(["AdminVehicleEntity"]),
  },
  filters: {
    date(value) {
      const date = moment(value, "YYYY-MM-DDThh:mm");
      return date.format("DD/MM/YYYY hh:mm");
    },
  },
  data() {
    return {
      entityReady: false,
      OnProps: null,
    };
  },
  async created() {
    this.init();
  },
  methods: {
    async init(callback) {
      try {
        await this.load({
          id: this.vehicleId,
        });
        this.entityReady = true;
        if (callback) {
          callback();
        }
      } catch (err) {
        console.log(err);
      }
    },
    onFormSave({ refresh, closeDialog, model, saving }) {
      saving(true);
      VehicleService.update(this.vehicleId, {
        ...this.$store.state.report,
        ...model,
      })
        .then(() => {
          this.init(refresh);
          this.$buefy.toast.open({
            type: "is-success",
            message: "נשמר בהצלחה!",
            duration: 4000,
          });
        })
        .catch(() => {
          this.$buefy.toast.open({
            type: "is-danger",
            message: "קרתה תקלה, השמירה לא התבצעה!",
            duration: 8000,
          });
          refresh();
        })
        .finally(() => {
          saving(false);
          closeDialog();
        });
    },
    onFormDiscard({ restoreState, closeDialog }) {
      restoreState();
      closeDialog();
    },
    ...mapActions({
      load: "vehicle/loadVehicleAdmin",
    }),
  },
};
</script>

<style>
</style>
