var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Vehicle__Entity" },
    [
      _c("Entity", {
        ref: "entity",
        attrs: {
          "entity-type": "Vehicle",
          "entity-id": _vm.vehicleId,
          innerComponent: _vm.innerComponent,
          innerComponentTitle: "פרטי רכב",
          title: "רכב",
          "back-button-text": "רשימת רכבים",
          "back-button-url": !_vm.isAdminWithoutProfileId
            ? "vehicles"
            : "admin/search/vehicles",
          widgets: _vm.widgets,
          actions: _vm.actions,
          preview: _vm.preview,
          "read-only": _vm.readOnly,
          entityReady: _vm.entityReady,
          activities: false,
          OnProps: _vm.OnProps
        },
        on: { onFormSave: _vm.onFormSave, onFormDiscard: _vm.onFormDiscard },
        scopedSlots: _vm._u([
          {
            key: "mandatory",
            fn: function() {
              return [
                _c(
                  "div",
                  {
                    staticClass: "Accident__Entity__Details__BaseDetails__Stage"
                  },
                  [
                    _c("span", { staticClass: "ltr" }, [_vm._v("מספר רכב:")]),
                    _c(
                      "span",
                      {
                        staticClass:
                          "\n            Accident__Entity__Details__BaseDetails__Stage__Value\n            value\n            has-text-weight-bold\n          "
                      },
                      [_vm._v(_vm._s(_vm.PlateNumber))]
                    )
                  ]
                ),
                _vm.CreateDate
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "Accident__Entity__Details__BaseDetails__Stage"
                      },
                      [
                        _c("span", { staticClass: "ltr" }, [
                          _vm._v("תאריך יצירה:")
                        ]),
                        _c(
                          "span",
                          {
                            staticClass:
                              "\n            Accident__Entity__Details__BaseDetails__Stage__Value\n            value\n            has-text-weight-bold\n          "
                          },
                          [_vm._v(_vm._s(_vm.displayDate))]
                        )
                      ]
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }